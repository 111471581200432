<!--
 * @Editors: wen jing
 -->
<template>
  <div>
    <Header title="detail"></Header>
    <div class="newsDetailBox">
      <div class="titleBox">
        <div class="title">
          <span>首页</span>
          <b>&gt;</b>
          <span>新闻动态</span>
          <b>&gt;</b>
          <span>文章详情</span>
        </div>
      </div>
      <div class="contBox">
        <div class="title">
          {{ detail.news_title }}
        </div>
        <div class="info">
          <span>发布时间：{{ detail.create_time }}</span>
          <span>发布人：{{ detail.creator }}</span>
          <span>阅读量：{{ detail.readings }}</span>
        </div>
        <hr />
        <img :src="detail.nd_img" alt="" />
        <p v-for="(item, index) in detail.news_content" :key="index">
          {{ item }}
        </p>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../components/header.vue";
import Footer from "../../components/footer.vue";
import newsData from "@/assets/json/newsData.json";
export default {
  name: "newsDetail",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      detail: {}
    };
  },
  methods: {
    getDetail: function(id) {
      this.detail = newsData.newsList[id];
    }
  },
  created() {
    this.getDetail(this.$route.params.id);
  }
};
</script>

<style lang="scss">
.newsDetailBox {
  width: 100%;
  margin-top: 98px;
  .titleBox {
    height: 50px;
    background: rgba(241, 243, 246, 1);
    .title {
      width: 1300px;
      margin: auto;
      span,
      b {
        font-size: 14px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 20px;
        display: inline-block;
        margin: 15px 0;
      }
      b {
        margin: 15px 19px;
      }
    }
  }
  .contBox {
    width: 1000px;
    margin: 41px auto;
    .title {
      font-size: 34px;
      font-weight: 600;
      color: rgba(51, 51, 51, 1);
      line-height: 48px;
      text-align: center;
    }
    .info {
      width: 100%;
      margin: 10px 0;
      span {
        font-size: 14px;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
        line-height: 20px;
        margin-right: 30px;
      }
    }
    hr {
      height: 1px;
      border: none;
      background: #eeeeee;
    }
    img {
      width: 100%;
      margin: 29px 0;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: rgba(102, 102, 102, 1);
      line-height: 30px;
      text-indent: 2em;
    }
  }
}
</style>
